.app-layout {
    min-height: 100%;
    background: transparent;
    .logo {
        width: 16rem;
        height: 2rem;
        margin: 2rem 3.2rem;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .ant-layout-sider {
        left: 0px;
        height: 100vh;
        overflow: auto;
        position: fixed;
        .ant-menu {
            .ant-menu-item {
                margin-bottom: 1.6rem;
                padding: 0 3.2rem !important;
            }
        }
    }
    .ant-layout {
        margin-left: 24rem;
        background: transparent;
    }
    .ant-layout-content {
        padding: 2.4rem 3.2rem;
    }
    .ant-layout-footer {
        background: transparent;
    }
}
