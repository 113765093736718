@import "./variable.scss";

.primary-color {
  color: $PRIMARY_COLOR;
}

@mixin text-base {
  &.border {
    border-bottom: 1px solid;
  }
  &.primary {
    color: $PRIMARY_TEXT_COLOR;
  }
  &.light {
    color: $LIGHT_TEXT_COLOR;
  }
  &.danger {
    color: #ff4d4f;
  }
  &.success {
    color: #87d068;
  }
  &.yellow {
    color: #ffc519;
  }
  &.normal {
    font-weight: 400;
  }
  &.medium {
    font-weight: 500;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
}

.text-small {
  line-height: 1.2;
  font-size: 1.2rem;
  @include text-base;
}

.text-xl {
  line-height: 1.6;
  font-size: 1.4rem;
  @include text-base;
}

.text-2xl {
  line-height: 1.6;
  font-size: 1.6rem;
  @include text-base;
}

.text-3xl {
  line-height: 1.6;
  font-size: 1.8rem;
  @include text-base;
}

.text-4xl {
  line-height: 1.6;
  font-size: 2rem;
  @include text-base;
}

.text-5xl {
  line-height: 1.3;
  font-size: 2.4rem;
  @include text-base;
}

.text-large {
  line-height: 1.8;
  font-size: 3.8rem;
  @include text-base;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

a,
.text-link {
  cursor: pointer;
  color: $PRIMARY_TEXT_COLOR;
  &:hover {
    color: $PRIMARY_COLOR !important;
  }
  &.no-border:not(:hover) {
    border: none;
  }
  &.border {
    border: 1px solid inherit;
  }
  &.reverse {
    color: $PRIMARY_COLOR;
  }
  @include text-base;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1 1 0%;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.transform-none {
  text-transform: none;
}

.line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100%;
}

.m-0 {
  margin: 0;
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.table {
  display: table;
}

.float-right {
  float: right;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.border-bottom {
  border-bottom: 1px solid $BORDER_COLOR;
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.m-auto {
  margin: 0 auto;
}

@include list-loop(".pt-", "padding-top");
@include list-loop(".mt-", "margin-top");
@include list-loop(".pb-", "padding-bottom");
@include list-loop(".mb-", "margin-bottom");
