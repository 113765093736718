@import "./mixins.scss";
@import "./variable.scss";
@import "./helpers.scss";

#root {
    height: 100%;
}

html {
    font-size: 62.5%;
    font-family: "Raleway", sans-serif;
}

body {
    margin: 0;
    font-family: "Raleway", sans-serif;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
    margin: 0;
    font-family: "Raleway", sans-serif;
}

body {
    font-weight: 400;
    font-size: 1.6rem;
    color: $PRIMARY_TEXT_COLOR;
    font-family: "Raleway", sans-serif;
    background: linear-gradient(107.54deg, #25253c 0%, #274054 0.01%, #050118 100%);
}

.ant-card.light-card-background {
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.05);
}

.asset-item {
    margin-bottom: 2rem;
    display: flex !important;
    > .ant-space-item {
        &:first-child {
            width: 12rem;
            .asset-name {
                line-height: 1.3;
                font-weight: 600;
                font-size: 1.6rem;
                text-transform: uppercase;
            }
        }
        &:last-child {
            flex: 1;
        }
    }
    &:last-child {
        margin-bottom: 1rem;
    }
}

.donate-page {
    display: flex;
    min-height: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    .ant-btn {
        width: 28rem;
        height: auto;
        padding: 1.4rem;
        color: #0e1128;
        font-weight: 700;
        font-size: 1.8rem;
        border-radius: 8px;
    }
}

.defi-protocols-page {
    .ant-card {
        background: transparent;
        .ant-card-head {
            padding: 0;
            border-bottom: 2px solid rgba(255, 255, 255, 0.3);
            .ant-card-head-title {
                font-weight: 700;
                font-size: 2rem;
                img {
                    width: 2.4rem;
                }
            }
        }
        .ant-card-body {
            padding: 2.4rem 0;
        }
        .protocol-items {
            .item {
                padding: 1.6rem;
                min-width: 26rem;
                border-radius: 16px;
                background: rgba(255, 255, 255, 0.05);
            }
            .ant-avatar {
                margin-top: -2px;
            }
        }
    }
}
