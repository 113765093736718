$breakpoints: (
    "mobile": (
        max-width: 576px,
    ),
    "tablet": (
        max-width: 768px,
    ),
) !default;

$PRIMARY_COLOR: #21354b;
$BORDER_COLOR: #ffffff4d;
$LIGHT_TEXT_COLOR: #ffffff80;
$PRIMARY_TEXT_COLOR: #ffffff;
// $SECONDARY_TEXT_COLOR: #595959;

$MAX_WIDTH: 148rem;
